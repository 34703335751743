import Vue from 'vue'
import wxCodePoput from './wxCodePoput.vue'
let WxCodePoput =Vue.extend((wxCodePoput))
let instance
var instal={}
instal.show= function (){
	instance=new WxCodePoput({
		data:{
			
		}
	})
	instance.$mount()
	if(!document.getElementById('wxCodePoput')){
		document.body.appendChild(instance.$el)
	}
}

instal.hidden=function(){//移除事件
	if(document.getElementById('wxCodePoput')){
		document.body.removeChild(document.getElementById('wxCodePoput'))
	}
}
export default instal
