// API
import api from "@/api/api.js";
// 所有权限
import qx from "@/common/qx.json";
import Tools from "@/utils/tools";
import animated from "animate.css";
import axios from "axios";
// 视频播放组件
// import VueVideoPlayer from 'vue-video-player'
// import 'video.js/dist/video-js.css'
// Vue.use(VideoPlayer)
// fade/zoom 等
import "element-ui/lib/theme-chalk/base.css";
// collapse 展开折叠
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import moment from "moment";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import Vue from "vue";
import VueAMap from "vue-amap";
import { VueCropper } from "vue-cropper";
import VueQuillEditor from "vue-quill-editor";
import TreeTable from "vue-table-with-tree-grid";
import vueToPdf from "vue-to-pdf";
import App from "./App.vue";
import "./assets/css/global.css";
import "./assets/fonts/iconfont.css";
// 屏幕适配
// import './lib/lib-flexible';
// 环信方法
import IM from "./common/WebIM.js";
//公共组件
import wxPoput from "./common/wxCodePoput/index.js";
import "./plugins/element.js";
import router from "./router";
import global_ from './Global';

Vue.use(vueToPdf);

Vue.use(animated);

Vue.use(VueAMap);
// 初始化vue-amap
VueAMap.initAMapApiLoader({
  // 高德的key
  key: "7b1cea482b7fac1e721125796c4fe122",
  // key: "8b64632ba824a0f17673252b7a05deb7",
  uiVersion: "1.0.11", // ui库版本，不配置不加载,
  // 插件集合'AMap.Geocoder','AMap.Autocomplete','AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView',
  //'AMap.Geolocation','AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'
  plugin: [
    "AMap.Geocoder",
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
    "AMap.Geolocation",
  ],
  // 高德 sdk 版本，默认为 1.4.4
  v: "1.4.4",
});

Vue.component("VueCropper", VueCropper);
Vue.component(CollapseTransition.name, CollapseTransition);

Vue.prototype.$IM = IM;

Vue.prototype.$api = api;

Vue.prototype.$wxPoput = wxPoput; //微信登录弹窗

Vue.prototype.$qx = qx;

Vue.prototype.GLOBAL_ = global_;  //挂载到Vue实例上面

Vue.use(Tools);
// 为了解决setItemEvent不立即生效，使用事件派发强制更高
Tools.dispatchEventStroage();

// axios.defaults.baseURL = 'http://192.168.1.11:8200/'
axios.defaults.baseURL = "https://freemen.work";

// 请求头拦截
axios.interceptors.request.use((config) => {
  NProgress.start();
  config.headers.Authorization = window.sessionStorage.getItem("token");
  return config;
});

axios.interceptors.response.use((config) => {
  NProgress.done();
  return config;
});
Vue.prototype.$http = axios;
Vue.prototype.$moment = moment;

Vue.component("tree-table", TreeTable);
Vue.use(VueQuillEditor);

Vue.filter("dateFormat", (originVal) => {
  const dt = new Date(originVal);
  const y = dt.getFullYear();
  const m = (dt.getMonth() + 1 + "").padStart(2, "0");
  const d = (dt.getDate() + 1 + "").padStart(2, "0");
  const hh = (dt.getHours() + 1 + "").padStart(2, "0");
  const mm = (dt.getMinutes() + 1 + "").padStart(2, "0");
  const ss = (dt.getSeconds() + 1 + "").padStart(2, "0");
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
});

Vue.config.productionTip = false;
router.afterEach(() => {
  // to, from, next
  // console.log("---to:", to, "---from:", from, "---next:", next);
  window.scrollTo(0, 0)
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
});
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
