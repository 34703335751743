import qs from "qs";
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const Admin = (resolve) => require(["../views/Home"], resolve);
const mycenter = (resolve) =>
  require(["../components/mycenter/index"], resolve);
const problem = (resolve) => require(["../components/problem/index"], resolve);
const aboutpc = (resolve) => require(["../components/about/index"], resolve);
const chat = (resolve) => require(["../components/message/index"], resolve);
const routes = [
  {
    path: "/login",
    name: "Login",
    component: (resolve) => require(["../components/Login.vue"], resolve),
    hidden: true,
  },
  {
    path: "/Welcome",
    name: "Welcome",
    component: (resolve) => require(["../components/Welcome.vue"], resolve),
    hidden: true,
  },
  {
    path: "/",
    name: "首页",
    component: Admin,
    children: [
      {
        path: "/",
        component: (resolve) =>
          require(["../components/homepage/index.vue"], resolve),
        name: "首页",
        hidden: true,
      },
    ],
  },
  {
    path: "/home",
    name: "首页",
    component: Admin,
    children: [
      {
        path: "/index",
        component: (resolve) =>
          require(["../components/homepage/index.vue"], resolve),
        name: "首页",
        hidden: true,
        meta: {
          //keepAlive: false,
        },
      },
    ],
  },
  {
    path: "/message",
    name: "消息",
    component: chat,
    children: [
      {
        path: "/chatall",
        component: (resolve) =>
          require(["../components/message/chat.vue"], resolve),
        name: "聊天界面",
        hidden: true,
        meta: {
          //keepAlive: false,
        },
      },
      {
        path: "/mestall",
        component: (resolve) =>
          require(["../components/message/message.vue"], resolve),
        name: "系统通知",
        hidden: true,
        meta: {
          //keepAlive: false,
        },
      },
    ],
  },
  {
    path: "/aboutpc",
    name: "关于",
    component: aboutpc,
    children: [
      {
        path: "/aboutus",
        component: (resolve) =>
          require(["../components/about/aboutus.vue"], resolve),
        name: "关于我们",
        hidden: true,
        meta: {
          // //keepAlive: false,
          reload: true,
        },
      },
    ],
  },
  {
    path: "/about",
    name: "问题",
    component: problem,
    children: [
      {
        path: "/commonwt",
        component: (resolve) =>
          require(["../components/problem/common.vue"], resolve),
        name: "常见问题",
        hidden: true,
        meta: {
          //keepAlive: false,
        },
      },
    ],
  },
  {
    path: "/mycenter",
    name: "个人中心",
    component: mycenter,
    children: [
      {
        path: "/myfree",
        component: (resolve) =>
          require(["../components/mycenter/myfree.vue"], resolve),
        name: "我的Freemen",
        hidden: true,
        meta: {
          //keepAlive: false,
        },
      },
      {
        path: "/information",
        component: (resolve) =>
          require(["../components/mycenter/personal/information.vue"], resolve),
        name: "个人信息",
        hidden: true,
        meta: {
          //keepAlive: false,
        },
      },
      {
        path: "/deliveryrecord",
        component: (resolve) =>
          require(["../components/mycenter/deliveryrecord.vue"], resolve),
        name: "投递记录",
        hidden: true,
        meta: {
          //keepAlive: false,
        },
      },
      {
        path: "/Interviewmanagement",
        component: (resolve) =>
          require(["../components/mycenter/Interviewmanagement.vue"], resolve),
        name: "面试管理",
        hidden: true,
        meta: {
          //keepAlive: false,
        },
      },
      {
        path: "/offermanagement",
        component: (resolve) =>
          require(["../components/mycenter/offermanagement.vue"], resolve),
        name: "offer管理",
        hidden: true,
        meta: {
          //keepAlive: false,
        },
      },
      {
        path: "/securityset",
        component: (resolve) =>
          require(["../components/mycenter/securityset.vue"], resolve),
        name: "安全设置",
        hidden: true,
        meta: {
          //keepAlive: false,
        },
      },
      {
        path: "/commonwords",
        component: (resolve) =>
          require(["../components/mycenter/commonwords.vue"], resolve),
        name: "常用语",
        hidden: true,
        meta: {
          //keepAlive: false,
        },
      },
      {
        path: "/shieldcompany",
        component: (resolve) =>
          require(["../components/mycenter/shieldcompany.vue"], resolve),
        name: "屏蔽公司",
        hidden: true,
        meta: {
          //keepAlive: false,
        },
      },
      {
        path: "/realname",
        component: (resolve) =>
          require(["../components/mycenter/realname.vue"], resolve),
        name: "实名认证",
        hidden: true,
        meta: {
          //keepAlive: false,
        },
      },
      {
        path: "/help",
        component: (resolve) =>
          require(["../components/mycenter/help.vue"], resolve),
        name: "反馈与帮助",
        hidden: true,
        meta: {
          //keepAlive: false,
        },
      },
    ],
  },
  {
    path: "/qiye",
    name: "企业",
    component: Admin,
    children: [
      {
        path: "/qiyeindex",
        component: (resolve) =>
          require(["../components/business/index.vue"], resolve),
        name: "企业列表",
        hidden: true,
        meta: {
          //keepAlive: false,
        },
      },
      {
        path: "/qiyedetail",
        component: (resolve) =>
          require(["../components/business/detail.vue"], resolve),
        name: "企业详情",
        // hidden: true,
        meta: {
          keepAlive: false
        },
      },
    ],
  },
  {
    path: "/position",
    name: "职位",
    component: Admin,
    children: [
      {
        path: "/positionindex",
        component: (resolve) =>
          require(["../components/position/index.vue"], resolve),
        name: "职位列表",
        hidden: true,
        meta: {
          //keepAlive: false,
        },
      },
      {
        path: "/positiondetail",
        component: (resolve) =>
          require(["../components/position/detail.vue"], resolve),
        name: "职位详情",
        hidden: true,
        // meta: {
        // 	keepAlive: true
        // },serachresult
      },
      {
        path: "/serachresult",
        component: (resolve) =>
          require(["../components/position/serachresult.vue"], resolve),
        name: "搜索结果",
        hidden: true,
        // meta: {
        // 	keepAlive: true
        // },
      },
    ],
  },
  {
    path: "/person",
    name: "个人信息",
    component: Admin,
    children: [
      {
        path: "/messagewrite",
        component: (resolve) =>
          require(["../components/person/messagewrite.vue"], resolve),
        name: "信息填写",
        hidden: true,
        meta: {
          //keepAlive: false,
        },
      },
      {
        path: "/realnameout",
        component: (resolve) =>
          require(["../components/person/realname.vue"], resolve),
        name: "实名认证",
        hidden: true,
        meta: {
          //keepAlive: false,
        },
      },
      {
        path: "/downapp",
        component: (resolve) =>
          require(["../components/person/downapp.vue"], resolve),
        name: "下载APP",
        hidden: true,
        meta: {
          //keepAlive: false,
        },
      },
      {
        path: "/previewresume",
        component: (resolve) =>
          require(["../components/person/previewresume.vue"], resolve),
        name: "预览简历",
        hidden: true,
        meta: {
          //keepAlive: false,
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  // 对于页面跳转，全部都返回到页面顶部。
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    // window.scrollTo(0, 0)
    // // chrome
    // document.body.scrollTop = 0
    // // firefox
    // document.documentElement.scrollTop = 0
    // // safari
    // window.pageYOffset = 0
    return { x: 0, y: 0 };
  },
});

//路由导航守卫
router.beforeEach((to, from, next) => {
  // 跳转回到顶部 chrome
  // window.document.body.scrollTop = 0
  // firefox
  // window.document.documentElement.scrollTop = 0
  // safari
  // window.pageYOffset = 0
  // console.log("job user router.each ----- to = ", to, ", from = ", from, ", next = ", next);
  const wx_url = window.location.href;
  const parseUrl = qs.parse(wx_url.split("?")[1]);
  if (parseUrl.code) {
    router.back(-1);
    window.sessionStorage.setItem("wxCode", JSON.stringify(parseUrl.code));
  }
  if (to.path === "/aboutus") return next();
  if (to.path === "/qiyeindex") return next();
  if (to.path === "/downapp") return next();
  if (to.path === "/home") return next();
  if (to.path === "/login") return next();
  if (to.path === "/messagewrite") return next();
  if (to.path === "/realnameout") return next();
  if (to.path === "/index") return next();
  if (to.path === "/positionindex") return next();
  if (to.path === "/qiyedetail") return next();
  if (to.path === "/positiondetail") return next();
  if (to.path === "/serachresult") return next();
  if (to.path === "/#/") return next();
  if (to.path === "/") return next();
  const tokenStr = window.sessionStorage.getItem("token");
  if (!tokenStr) return next("/login");
  next();
});
export default router;
