<script>
const LoginStatus = false;
const UserInfo_Id = 0;
export default {
  // name: "Global",
  LoginStatus, // 登录状态值
  UserInfo_Id, // 当前用户ID
}
</script>

