import WebIM from "easemob-websdk"
import config from "./WebIMConfig.js"
import emoji from "./emoji.js"
let conn = {};
WebIM.config = config;
WebIM.emoji = emoji
conn = WebIM.conn = new WebIM.connection({
		appKey: WebIM.config.appkey,
		isHttpDNS: WebIM.config.isHttpDNS,
		isMultiLoginSessions: WebIM.config.isMultiLoginSessions,
		https: WebIM.config.https,
		url: WebIM.config.socketServer,
		apiUrl: WebIM.config.restServer,
		isAutoLogin: WebIM.config.isAutoLogin,
		autoReconnectNumMax: WebIM.config.autoReconnectNumMax,
		autoReconnectInterval: WebIM.config.autoReconnectInterval,
		delivery: WebIM.config.delivery,
		useOwnUploadFun: WebIM.config.useOwnUploadFun
})
console.log(WebIM, 'WebIM');
let IM = {
	register: (data)=>{
		let config = {
			username: 'free_user_'+data.id,
			password: '123456',
			nickname: '',
			appKey: WebIM.config.appkey,
			success:data.success,
			error:data.error
		}
		conn.registerUser(config);
	},
	login: (data)=>{
		let config = {
			user: 'free_user_'+data.id,
			pwd: '123456',
			appKey: WebIM.config.appkey,
			error:data.error,
			success:data.success
		}
		conn.open(config);
	},
	outLogin: ()=>{
		conn.close();
	},
	getList: ()=>{
    /**
     * https://docs-im.easemob.com/im/web/basics/message?s[]=getsessionlist
     * 获取会话列表
     */
		return conn.getSessionList()
	},
	getContent: (data)=>{
		let config = {
			queue: data.id,
			isGroup: false,
			count: 20,
			success: data.success,
		}
		conn.fetchHistoryMessages(config)
	},
	refreshContentCatch: ()=>{  // 清除游标缓存
		conn.mr_cache = [];
	},
	read: (data)=>{ //已读
		var msg = new WebIM.message('channel',conn.getUniqueId());
		msg.set({
			to: data.id
		});
		conn.send(msg.body);
	},
	sendText: (data)=>{
		if(/^\s*$/.test(data.content)) return;
		let id = conn.getUniqueId();                 // 生成本地消息id
		let msg = new WebIM.message('txt', id);      // 创建文本消息
		// console.log("WebIM sendText data = ", data);
		msg.set({
			msg: data.content,                  // 消息内容
			to: 'free_company_'+data.id,           // 接收消息对象（用户id）
			chatType: 'singleChat',                  // 设置为单聊
			ext: {
				chat_attribute_type:data.chat_attribute_type ? data.chat_attribute_type : '',
				chat_attribute_avatar_from:data.chat_attribute_avatar_from,
				chat_attribute_avatar_to:data.chat_attribute_avatar_to,
				chat_attribute_company_id:data.chat_attribute_company_id,
				chat_attribute_gender_from:data.chat_attribute_gender_from,
				chat_attribute_gender_to:data.chat_attribute_gender_to,
				chat_attribute_job_intention_id:data.chat_attribute_job_intention_id,
				chat_attribute_nickname_from:data.chat_attribute_nickname_from,
				chat_attribute_nickname_to:data.chat_attribute_nickname_to,
				chat_attribute_position_id:data.chat_attribute_position_id,
				chat_attribute_position_name:data.chat_attribute_position_name,
				chat_attribute_handStatus:data.chat_attribute_handStatus,
				chat_attribute_companyUserId:data.chat_attribute_companyUserId,
				chat_attribute_userId:data.chat_attribute_userId,
			},                                  //扩展消息
			success: data.success,
			fail: function(e){
				// 失败原因:
				// e.type === '603' 被禁言
				// e.type === '605' 群组不存在
				// e.type === '602' 不在群组或聊天室中
				// e.type === '504' 撤回消息时超出撤回时间
				// e.type === '505' 未开通消息撤回
				// e.type === '506' 没有在群组或聊天室白名单
				// e.type === '501' 消息包含敏感词
				// e.type === '502' 被设置的自定义拦截捕获
				// e.type === '503' 未知错误
				let message = '';
				switch(Number(e.type)) {
					case 603:
						message = '被禁言';
						break;
					case 605:
						message = '群组不存在';
						break;
					case 602:
						message = '不在群组或聊天室中';
						break;
					case 504:
						message = '撤回消息时超出撤回时间';
						break;
					case 505:
						message = '未开通消息撤回';
						break;
					case 506:
						message = '没有在群组或聊天室白名单';
						break;
					case 501:
						message = '消息包含敏感词';
						break;
					case 502:
						message = '被设置的自定义拦截捕获';
						break;
					default:
						message = 'im数据异常';
						break;
				}
				data.fail && data.fail(message);
				// console.log(e,"Send private text error");
			},
		})
		conn.send(msg.body);
	},
	// 发送消息
	send: (data)=>{
		if(!data.type) data.type = 'txt';
		// console.log("before send mes, data = ", data);
		let ext = {									 // //扩展消息
			chat_attribute_address_detail:"",
			chat_attribute_type:data.type,
			chat_attribute_avatar_from:data.chat_attribute_avatar_from,
			chat_attribute_avatar_to:data.chat_attribute_avatar_to,
			chat_attribute_company_id:data.chat_attribute_company_id,
			chat_attribute_gender_from:data.chat_attribute_gender_from,
			chat_attribute_gender_to:data.chat_attribute_gender_to,
			chat_attribute_job_intention_id:data.chat_attribute_job_intention_id,
			chat_attribute_nickname_from:data.chat_attribute_nickname_from,
			chat_attribute_nickname_to:data.chat_attribute_nickname_to,
			chat_attribute_position_id:data.chat_attribute_position_id,
			chat_attribute_position_name:data.chat_attribute_position_name,
			chat_attribute_handStatus:data.chat_attribute_handStatus,
			chat_attribute_companyUserId:data.chat_attribute_companyUserId,
			chat_attribute_userId:data.chat_attribute_userId,
		};
		let option = {};
		if(data.type && data.type == 'txt'){
			option = {
				msg: data.content,                  // 消息内容
				to: 'free_company_'+data.id,           // 接收消息对象（用户id）
				chatType: 'singleChat',                  // 设置为单聊
				type: 'txt',		  // 消息类型: 'txt':文本消息,'loc':位置消息,'custom':自定义消息,'img':图片消息,'cmd':命令消息
				ext: ext,
				success: data.success,
				fail: function(e){
					// 失败原因:
					// e.type === '603' 被禁言
					// e.type === '605' 群组不存在
					// e.type === '602' 不在群组或聊天室中
					// e.type === '504' 撤回消息时超出撤回时间
					// e.type === '505' 未开通消息撤回
					// e.type === '506' 没有在群组或聊天室白名单
					// e.type === '501' 消息包含敏感词
					// e.type === '502' 被设置的自定义拦截捕获
					// e.type === '503' 未知错误
					console.log(e,"Send private text error");
				},
			}
		} else if(data.type === 'img'){
			const url = data.content.url;
			option = {
				chatType: 'singleChat',
				to: 'free_company_'+data.id,           // 接收消息对象（用户id）
				msg: data.content.url,
				ext: ext,
				width: data.content.width,
				height: data.content.height,
				type: 'img',
				// url:
				url,
				// success: data.success,
				// fail: function(e){
				// 	console.log(e,"Send private text error");
				// },
			}
		} else if(data.type === 'custom'){
			option = {
				customEvent: data.content,                  // 消息内容
				to: 'free_company_'+data.id,           // 接收消息对象（用户id）
				chatType: 'singleChat',                  // 设置为单聊
				type: 'custom',		  // 消息类型: 'txt':文本消息,'loc':位置消息,'custom':自定义消息,'img':图片消息,'cmd':命令消息
				ext: ext,
				success: data.success,
				params: {},
				customExts: {},
				fail: function(e){
					console.log(e,"Send private text error");
				},
			}
		} else if(data.type === 'loc'){
			ext.chat_attribute_address_detail = data.content.addDetail;
			option = {
				to: 'free_company_'+data.id,           // 接收消息对象（用户id）
				chatType: 'singleChat',                  // 设置为单聊
				type: 'loc',		  // 消息类型: 'txt':文本消息,'loc':位置消息,'custom':自定义消息,'img':图片消息,'cmd':命令消息
				buildingName:"",
				addr: data.content.addName,
				lat: data.content.lat,
				lng: data.content.lng,
				ext: ext,
				success: data.success,
				fail: function(e){
					console.log(e,"Send private text error");
				},
			}
		} else {
			option = {
				msg: data.content,                  // 消息内容
				to: 'free_company_'+data.id,           // 接收消息对象（用户id）
				chatType: 'singleChat',                  // 设置为单聊
				type: 'txt',		  // 消息类型: 'txt':文本消息,'loc':位置消息,'custom':自定义消息,'img':图片消息,'cmd':命令消息
				ext: ext,
				success: data.success,
				fail: function(e){
					console.log(e,"Send private text error");
				},
			}
		}
		if(option === {}){
			console.log("Send error");
			return;
		}
		if(data.type==='img'){
			// debugger
			let msg = WebIM.message.create(option);
			conn.send(msg).then((res) => {
					// console.log('success --- 消息发送成功', res); // 消息发送成功。
					data.success(res.localMsgId, res.serverMsgId);
				})
				.catch((e) => {
					console.log('fail', e); // 如禁言或拉黑后消息发送失败。
				});
			return;

		}
		let id = conn.getUniqueId();                 // 生成本地消息id
		// let msg = new WebIM.message(data.type, id);      // 创建文本消息
		// let msg = WebIM.message.create(option);
		let params = {
			type: data.type,
			id: id,
		};
		let msg = WebIM.message.createOldMsg(params);
		// debugger
		// console.log(">>>>>> type=", data.type, ", config = ", option);
		msg.set(option);
		conn.send(msg.body);
		// .then(() => {
		// 	console.log('Success'); // 消息发送成功。
		// }).catch((e) => {
		// 	console.log('Fail', e); // 如禁言或拉黑后消息发送失败。
		// });
	},
	onTextMessage:(callback)=>{
		console.log(">>>>>WebIM onTextMessage----");
		conn.listen({
			onTextMessage:(message)=>{
				callback(message)
			}
		})
	},
	// onPictureMessage:(callback)=>{
	// 	console.log(">>>>>WebIM onPictureMessage----");
	// 	conn.listen({
	// 		onPictureMessage:(message)=>{
	// 			callback(message)
	// 		}
	// 	})
	// },
	onImageMessage: (callback)=>{
		console.log(">>>>WebIM onPictureMessage----");
		conn.addEventHandler("onImageMessage", {
			onImageMessage:( message )=>{
				callback(message)
			},
		})
	},
	onLocationMessage: (callback)=>{
		console.log(">>>WebIM onLocationMessage----");
		// conn.listen({
		// 	onLocationMessage:(message)=>{
		// 		callback(message)
		// 	}
		// })
		conn.addEventHandler("messageListen", {
			onLocationMessage:( message )=>{
				callback(message)
			},
		})
	},
	onCustomMessage: (callback)=>{
		console.log(">>>WebIM onCustomMessage----");
		// conn.listen({
		// 	onLocationMessage:(message)=>{
		// 		callback(message)
		// 	}
		// })
		conn.addEventHandler("onCustomMessage",{
			onCustomMessage:( message )=>{
				callback(message)
			},
		})
	},
	onReceivedMessage : (callback)=>{
		console.log(">>>WebIM onReceivedMessage----");
		conn.addEventHandler('onReceivedMessage', {
			onReceivedMessage:(message)=>{
				callback(message)
			}
		})
	},
	removeEventHandlerAll(id){
		if(id==='all' || id === 'messageListen') conn.removeEventHandler("messageListen");
		if(id==='all' || id === 'onImageMessage') conn.removeEventHandler("onImageMessage");
		if(id==='all' || id === 'onLocationMessage') conn.removeEventHandler("onLocationMessage");
		if(id==='all' || id === 'onCustomMessage') conn.removeEventHandler("onCustomMessage");
		if(id==='all' || id === 'onReceivedMessage') conn.removeEventHandler("onReceivedMessage");
		// if(id==='all' || id === 'onTextMessage') conn.removeEventHandler("onTextMessage");
	},
  recallMessage(option) {// 撤回
    conn.recallMessage(option);
  },
  deleteSession(option) {// 删除会话
    const { userID, } = option;
    return conn.deleteSession({
      channel: userID, // 会话 ID（对方的 userID 或群组 ID）。
      chatType: 'singleChat', // 会话类型 singleChat（单聊） groupChat（群聊）。
      deleteRoam: true, // 是否同时删除服务端漫游消息。
    })
  }
}
conn.addEventHandler('omImageMessage', {
	onImageMessage: (msg) => {
		// console.log('>>>收到ImageMessage-------', msg);
	}, // 收到图片消息。
})
conn.addEventHandler('onReceivedMessage', {
	onReceivedMessage: (msg) => {
		// console.log('>>>收到 onReceivedMessage -------', msg);
	}, // 收到图片消息。
})
conn.addEventHandler('messageListen',{
	onLocationMessage: (msg) => {
		// console.log('>>>收到LocationMessage-------', msg);
	}, // 收到位置消息。
	onCustomMessage: (msg) => {
		// console.log('>>>收到CustomMessage---------', msg);
	}, // 收到自定义消息。
})
conn.listen({
		onOpened: function () {
			console.log("IM listener opened")
		},                  //连接成功回调
		onClosed: function () {
			console.log("IM listener closed")
		},                  //连接关闭回调
		// onTextMessage: function ( message ) {
		// 	console.log("-------",message)
		// },    //收到文本消息
		onEmojiMessage: function ( message ) {
			// console.log(message)
		},   //收到表情消息
		// onPictureMessage: function ( message ) {
		// 	console.log(message)
		// },  //收到图片消息
		onCmdMessage: function ( message ) {
			// console.log(message)
		},     //收到命令消息
		onAudioMessage: function ( message ) {
			// console.log(message)
		},   //收到音频消息
		// onLocationMessage: function ( message ) {
		// 	console.log(message)
		// },//收到位置消息
		onFileMessage: function ( message ) {
			// console.log(message)
		},    //收到文件消息
		// onCustomMessage: function ( message ) {
		// 	console.log(message)
		// },  //收到自定义消息
		onVideoMessage: function (message) {
				var node = document.getElementById('privateVideo');
				var option = {
						url: message.url,
						headers: {
							'Accept': 'audio/mp4'
						},
						onFileDownloadComplete: function (response) {
								var objectURL = WebIM.utils.parseDownloadResponse.call(conn, response);
								node.src = objectURL;
							},
						onFileDownloadError: function () {
							console.log('File down load error.')
						},
				};
				WebIM.utils.download.call(conn, option);
		},   //收到视频消息
		onPresence: function ( message ) {
			// console.log(message)
		},       //处理“广播”或“发布-订阅”消息，如联系人订阅请求、处理群组、聊天室被踢解散等消息
		onRoster: function ( message ) {
			// console.log(message)
		},         //处理好友申请
		onInviteMessage: function ( message ) {
			// console.log(message)
		},  //处理群组邀请
		onOnline: function () {
			console.log("IM listener onLine successfully!")
		},                  //本机网络连接成功
		onOffline: function () {
			console.log("Net error! IM listener offLine!")
		},                 //本机网络掉线
		onError: function ( message ) {
			// console.log(message)
		},          //失败回调
		onBlacklistUpdate: function (list) {       //黑名单变动
			// 查询黑名单，将好友拉黑，将好友从黑名单移除都会回调这个函数，list则是黑名单现有的所有好友信息
			// console.log(list);
		},
		onRecallMessage: function(message){
			// console.log(message, '撤回了')
		},      //收到撤回消息回调
		onReceivedMessage: function(message){
			// console.log(message)
		},    //收到消息送达服务器回执
		onDeliveredMessage: function(message){
			// console.log(message)
		},   //收到消息送达客户端回执
		onReadMessage: function(message){
			// console.log(message)
		},        //收到消息已读回执
		onCreateGroup: function(message){
			// console.log(message)
		},        //创建群组成功回执（需调用createGroupNew）
		onMutedMessage: function(message){
			// console.log(message)
		},       //如果用户在A群组被禁言，在A群发消息会走这个回调并且消息不会传递给群其它成员
		onChannelMessage: function(message){
			// console.log(message)
		}      //收到整个会话已读的回执，在对方发送channel ack时会在这个回调里收到消息
});
if(sessionStorage.getItem('token') && !window.noIMlogin){
	let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
	if(!window.IMlogin){
		IM.login({
			id:userInfo.id,
			success:()=>{
				window.IMlogin = true
			}
		})
	}
}
export default IM
