<template>
  <div class="">
    <el-dialog
      :visible.sync="dialogVisible"
      width="800px"
      :show-close="false"
      :modal-append-to-body="false"
      :lock-scroll="false"
    >
      <div class="details">
        <span class="closeicon" @click="closebox()">
          <i class="el-icon-error"></i>
        </span>
        <div class="wb-tit font18">请选择城市</div>
        <div class="location font14">
          <span>当前定位：</span>
          <i class="el-icon-location-outline"></i>
          <span>{{ localtion }}</span>
        </div>
        <div class="cityjz">
          <div
            :class="activenum == item.id ? 'activeclass' : ''"
            v-for="(item, index) in cityHeader"
            :key="index"
            @click="changecity(item)"
          >
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="citybox">
          <div
            class="font14"
            :class="{ active: item.name == selectName }"
            v-for="(item, index2) in citymain"
            :key="index2"
            @click="selectCity(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
import { city } from "../../common/city.js";
export default {
  data() {
    return {
      dialogVisible: false,
      cityHeader: [
        { name: "ABCD", id: 1 },
        { name: "EFJH", id: 2 },
        { name: "IGKL", id: 3 },
        { name: "MNOP", id: 4 },
        { name: "QRSTU", id: 5 },
        { name: "VWXYZ", id: 6 },
      ],
      citymain: [],
      activenum: 1,
      callback: null,
      selectName: "",
      localtion: "",
    };
  },
  created() {
    this.getLocation();
  },

  mounted() {
    Bus.$on("citystuts", (data) => {
      this.dialogVisible = data.show;
      this.callback = data.callback;
      this.selectName = data.selectCity;
      if (data.selectCity) {
        let py = "";
        city.map((item) => {
          if (item.name == data.selectCity) {
            py = item.py;
          }
        });
        this.cityHeader.map((item, index) => {
          if (item.name.indexOf(py) != -1) {
            this.changecity(this.cityHeader[index]);
          }
        });
      } else {
        this.changecity(this.cityHeader[0]);
      }
      setTimeout( ()=>{
        this.getLocation();
      },10);
    });
  },
  methods: {
    getLocation() {
      // 获取当前位置
      const self = this;
      AMap.plugin("AMap.Geolocation", function () {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
        });
        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, "complete", onComplete);
        AMap.event.addListener(geolocation, "error", onError);
        function onComplete(data) {
          // data是具体的定位信息
          self.localtion = data.city;
        }
        function onError(data) {
          // 定位出错
          console.log("定位失败错误：", data);
          // 调用ip定位
          self.getLngLatLocation();
        }
        // geolocation.getCityInfo((starts, res) => {
        //   self.localtion = res.city;
        // });
      });
    },
    getLngLatLocation() {
      const self = this;
      AMap.plugin("AMap.CitySearch", function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function (status, result) {
          // console.log("chooseCity.getLngLatLocation, AMap.CitySearch().getLocalCity.result = ", result);
          if (status === "complete" && result.info === "OK") {
            self.localtion = result.city;
          }
        });
      });
    },
    closebox() {
      this.dialogVisible = false;
    },
    changecity(data) {
      this.activenum = data.id;
      let arr = [];
      city.map((item) => {
        if (data.name.indexOf(item.py) != -1) {
          arr.push(item);
        }
      });
      this.citymain = arr;
    },
    selectCity(data) {
      this.selectName = data.name;
      sessionStorage.setItem("selectCity", data.name);

      this.callback(data.name);
      this.closebox();
    },
  },
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.details {
  .wb-tit {
    padding: 17px 0 17px 30px;
    text-align: left;
    background-color: #ffffff;
    color: #000000;
    border-bottom: 1px solid #dddddd;
  }
  .location {
    text-align: left;
    margin-right: 50px;
    padding: 17px 0 17px 30px;
    .el-icon-location-outline {
      color: #00bcff;
      margin-right: 5px;
      font-size: 15px;
    }
    span {
      color: #111111;
    }
  }
  .cityjz {
    background-color: #f3f3f3;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    div:hover {
      cursor: pointer;
      color: #00bcff;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 50px;
      color: #111111;
    }
    .activeclass {
      color: #00bcff;
      span {
        height: 95%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid #00bcff;
      }
    }
  }
  .citybox {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0 100px 0;
    div:hover {
      cursor: pointer;
      color: #00bcff;
    }
    div {
      width: 160px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #111111;
    }
    .active {
      color: #00bcff;
    }
  }
}
</style>
