import { $http } from "@/utils/requestfu";

let config = {
  getdict: "/user/system/dict", //获取字典
  phonemsg: "/user/login/msg", //短信接口
  codeLogin: "/user/login/web/codeLogin", //验证码登录
  passwordLogin: "/user/login/web/passwordLogin", //密码登录
  outLogin: "/user/login/web/logout", //退出登录
  rePassword: "/user/login/resetPassword", //重置密码
  getQrcode: "/user/pcLogin/qrcode", //获取二维码
  wxCallback: "/user/pcLogin/wx/web/callback", //微信回调
  wxLogin: "/user/login/wx/web/login", //微信登录

  getMesNoread: "/user/message/unReadCount", //获取未读信息
  getMesList: "/user/message/list", //获取消息列表
  messageRead: "/user/message/read", //已读

  getUserData: "/user/user/getData", //获取用户数据
  getUserInfo: "/user/user/getInfo", //获取用户信息
  getUserId: "/user/user/getUserId", //获取用户ID值
  updateUserInfo: "/user/user/update", //修改用户信息
  realName: "/user/user/updateId", //实名认证
  changePass: "/user/user/updatePassword", //更换密码
  changePhone: "/user/user/updatePhone", //更换手机号
  changeWorkStatus: "/user/user/updateWorkStatus", //更改在职状态
  changeWorkTime: "/user/job/updateWorkTime", //更改在职状态
  upload: "/user/file/upload", //上传

  getJobList: "/user/work/getJob", //获取职位列表
  getJobDetail: "/user/work/jobDetail", //获取职位详情
  getJobInfo: "/user/work/jobInfo",     //免登录获取职位信息
  refuseJobList: "/user/work/getWaitJob", //再等等拒绝握手的列表
  saveHandle: "/user/work/accept", //接受拒绝握手
  getWantJob: "/user/job/getJob", //获取求职意向
  delWantJob: "/user/job/delJob", //删除求职意向
  saveWantJob: "/user/job/saveJob", //保存求职意向

  getPostRecord: "/user/popup/getPostCv", //获取简历投递记录

  pic51Resume: "/user/cv/addCVFrom51Image", //上传51图片简历
  picLjResume: "/user/cv/addCVFromImage", //上传拉钩图片简历
  docLpResume: "/user/cv/addCVFromLiePinDoc", //上传猎聘doc简历
  addressBossResume: "/user/cv/addCVFromUrl", //上传boss链接简历
  addressBossUrl: "/user/cv/addCVFromBossUrl", //上传boss简历链接
  addressZlResume: "/user/cv/addCVFromZhiPinUrl", //上传智联链接简历
  addressZlResume2: "/user/cv/addCVFromZhiPinUrlWebV2", //上传智联链接简历
  viewResume: "/user/cv/preview", //简历预览
  saveResume: "/user/cv/savePreview", //简历预览保存
  saveWorkExp: "/user/cv/saveWorkExp", //保存工作经历
  delWorkExp: "/user/cv/delWorkExp", //删除工作经历
  saveEducation: "/user/cv/saveEducation", //保存教育经历
  delEducation: "/user/cv/delEducation", //删除教育经历
  saveProjectExp: "/user/cv/saveProjectExp", //保存项目经历
  delProjectExp: "/user/cv/delProjectExp", //删除项目经历
  saveCert: "/user/cv/saveHonorCert", //保存证书
  delCert: "/user/cv/delHonorCert", //删除证书
  saveLanguage: "/user/cv/saveLanguage", //保存语言能力
  delLanguage: "/user/cv/delLanguage", //删除语言能力
  saveVideo: "/user/cv/saveCV", //保存视频简历

  getInterviewList: "/user/popup/getInterview", //获取面试列表
  saveInterview: "/user/popup/saveInterview", //接受拒绝面试
  getOfferList: "/user/popup/getOffer", //获取offer列表
  saveOffer: "/user/popup/saveOffer", //接受拒绝offer

  getPhraseList: "/user/phrase/list", //获取常用语
  savePhrase: "/user/phrase/save", //保存常用语
  delPhrase: "/user/phrase/del", //删除常用语

  getBlackCompanyList: "/user/company/blacklist", //获取屏蔽公司
  blackCompany: "/user/company/black", //拉黑取消拉黑公司
  searchCompany: "/user/company/list", //搜索公司
  getCompanyDetail: "/user/company/getDetail", //获取公司详情

  advise: "/user/system/advise", //意见反馈
  warn: "/user/system/warn", //举报
  banner: "/user/advert/get", //轮播图

  searchJob: "/user/search/job", //搜索职位
  sendResume: "/user/search/send", //发送简历

  getQuestionList: "/user/system/question", //获取常见问题列表

  getCompanyList: "user/company/list", //获取公司列表
  getCompanyJobList: "user/company/jobList", //获取公司所有工作

  getHotSkill: "user/system/hotSkill", //获取热门标签
  getNewJob: "user/work/getNewJob", //获取最新职位
  getRecommendJob: "user/work/getRecommendJob", //获取推荐职位
  zhipin: "user/zhipin/data.json", //获取推荐职位
  zhilian: "user/zhilian/api/capi", //获取推荐职位
  agreement: "user/system/agreement", //获取推荐职位
  getToken: "/user/pcLogin/getToken",
  getDetail: "/user/user/getDetail",
  addContactWay:"user/cv/addChatRecord", // 同意对方的联系方式请求
  getChatRecord:"user/cv/getChatRecord", // 查询联系方式授权状态
  getSkillList: "user/system/getSkillDict", //获取技能列表

  testMail: "/user/system/test",
  testEMPush: "/user/system/testPush",
  testExcel: "/user/cv/outputPreviewExcelModel",
};

let exports = {};
for (var k in config) {
  let url = config[k];
  exports[k] = function(type, data) {
    if (type == "get") {
      return $http.get(url, data);
    } else if (type == "boss") {
      return $http.get(url + data);
    } else if (type == "zhilian") {
      return $http.get(url, data);
    } else {
      return $http.post(url, data);
    }
  };
}

export default exports;
