<template>
  <div id="app" ref="box">
    <router-view v-if="isRouterAlive"></router-view>
    <!-- 视频简历 -->
    <videodetail></videodetail>
    <!-- 城市切换弹窗 -->
    <choosecity></choosecity>
  </div>
</template>

<script>
import choosecity from "./components/homepage/choosecity.vue";
import videodetail from "./components/mycenter/personal/videodetail.vue";
export default {
  components: {
    videodetail,
    choosecity,
  },
  name: "app",
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  mounted() {
    window.addEventListener('hashchange', (e) => {
      var currentPath = window.location.hash.slice(1); // 获取输入的路由
      // console.log("AppConfig hashChange mode log.e = ", e);
      if (this.$router.path !== currentPath) {
        this.$router.push(currentPath); // 动态跳转
      }
    }, false);
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  },
};
</script>

<style>
.el-message {
  top: 80px !important;
}
/* 全页面置灰 */
/*html{*/
/*  -webkit-filter:grayscale(100%);*/
/*  -moz-filter:grayscale(100%);*/
/*  -ms-filter:grayscale(100%);*/
/*  -o-filter:grayscale(100%);*/
/*  filter:grayscale(100%);*/
/*  filter:url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");*/
/*  filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1)*/
/*}*/
</style>
