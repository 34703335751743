<template>
	<div class="">
		<el-dialog :visible.sync="videodialogVisible" width="580px" :show-close="false" :modal-append-to-body="false" :lock-scroll="false">
			<div class="details">
				<span class="closeicon" @click="closebox()"><i class="el-icon-error"></i></span>
				<div class="videobox">
					<!-- <div class="prism-player" id="J_prismPlayer" style="margin-left: auto; margin-right: auto;"></div> -->
					<video-player class="video-player vjs-custom-skin" v-if="playshow" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
					<div class="message">
						<div class="person-info">
							<div class="p-news font20">
								{{videoName}}
							</div>
							<div class="p-dec font14">
								{{videoContent}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Bus from '../../../common/bus.js';
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
export default {
	components: {
		videoPlayer
	},
	data() {
		return {
			playshow:true,
			videodialogVisible: false,
			videoName:'',
			videoContent:'',
			playerOptions: {
				playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
				autoplay: false, //如果true,浏览器准备好时开始回放。
				muted: false, // 默认情况下将会消除任何音频。
				loop: false, // 导致视频一结束就重新开始。
				preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
				language: 'zh-CN',
				aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
				fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
				sources: [
					{
						src: '', // 路径
						type: 'video/mp4' // 类型
					},
					// {
					// 	src: '//path/to/video.webm',
					// 	type: 'video/webm'
					// }
				],
				poster: '', //你的封面地址
				// width: document.documentElement.clientWidth,
				notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
				controlBar: {
					timeDivider: true,
					durationDisplay: true,
					remainingTimeDisplay: false,
					fullscreenToggle: true //全屏按钮
				}
			}
		};
	},
	created() {
		
	},
	mounted() {
		Bus.$on('videodstuts', data => {
			this.videodialogVisible = data.show;
			this.playshow=data.show;
			this.playerOptions.sources[0].src = 'https://freemen.work/user/file/view/'+data.data.resUrl
			this.videoName = data.data.cvName
			this.videoContent = data.data.cvDescription
		});
	},
	methods: {
		closebox() {
			this.videodialogVisible = false;
			this.playshow=false;
		},
		getdata(){
			console.log('获取个人详情')
			this.playerOptions.sources[0].src='http://vjs.zencdn.net/v/oceans.mp4';
			this.playerOptions.poster='https://img0.baidu.com/it/u=2619691961,843226653&fm=253&fmt=auto&app=120&f=GIF?w=567&h=300'
		},
		
		
	}
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
	padding: 0 !important;
}
.video-js .vjs-big-play-button{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
</style>
<style lang="less" scoped>

.closeicon {
	position: absolute;
	top: -40px;
	right: 0;
	font-size: 26px;
	color: #f3f3f3;
}
.details {
	.wb-tit {
		padding: 17px 0;
		text-align: center;
		background-color: #f3f3f3;
		color: #000000;
		font-weight: 600;
	}
}

.message{
	padding: 20px 30px;
	.person-info {
		.p-news {
			color: #111111;
		}
		.p-dec{
			margin-top: 20px;
			color: #666666;
			line-height: 1.5;
		}
	}
}




</style>
